<template>
  <b-card no-body>
    <b-card-header class="pb-50">
      <h5>
        {{ $t('market.filters.title') }}
      </h5>
    </b-card-header>
    <b-card-body>
      <b-row>
        <b-col
          cols="12"
          md="6"
          class="mb-md-0 mb-2"
        >
          <label for="status">{{ $t('market.table.fields.status') }}</label>
          <v-select
            id="status"
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            :value="statusFilter"
            :options="statusProjectOptions"
            class="w-100"
            :reduce="val => val.value"
            @input="(val) => $emit('update:statusFilter', val)"
          />
        </b-col>
        <b-col
          cols="12"
          md="6"
          class="mb-md-0 mb-2"
        >
          <label for="status">{{ $t('market.table.fields.user') }}</label>
          <v-select
            id="status"
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            :value="userIdFilter"
            :options="userOptions"
            class="w-100"
            :reduce="val => val.value"
            @input="(val) => $emit('update:userIdFilter', val)"
            @search="searchUser"
          />
        </b-col>
      </b-row>
    </b-card-body>
  </b-card>
</template>

<script>
import GlobalMixin from '@/mixins/GlobalMixin'

export default {
  mixins: [GlobalMixin],
  props: {
    statusFilter: {
      type: [String, null],
      default: null,
    },
    userIdFilter: {
      type: [Number, String, null],
      default: null,
    },
  },
  data() {
    return {
      userOptions: [],
    }
  },
  async beforeCreate() {
    await this.$http.get('/api/users')
      .then(response => {
        this.userOptions = this.$options.filters.transformForVSelect(response.data.data, 'id', 'name')
      })
  },
  methods: {
    async searchUser(query) {
      await this.$http.get('/api/users', {
        params: {
          query,
        },
      })
        .then(response => {
          this.userOptions = this.$options.filters.transformForVSelect(response.data.data, 'id', 'name')
        })
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
